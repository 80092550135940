<template>
  <div>
    <vs-tabs>
      <vs-tab label="Expense PO" icon-pack="feather" icon="icon-box">
        <div class="tab-text">
          <!-- ADD DEPARTMENT FORM  -->
          <div class="vx-row">
            <!-- HORIZONTAL LAYOUT -->
            <div class="vx-col w-full">
              <vx-card class="form-title" title="Expense PO">
                <form>
                  <div class="vx-row">
                    <!-- LEFT SIDE -->
                    <div class="vx-col md:w-3/4 mx-auto">
                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Name</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <div class>
                            <vs-input
                              v-validate="'required'"
                              name="name"
                              v-model="Expenseform.field_name"
                              class="w-full"
                            />
                            <!-- <span
                              class="text-danger text-sm"
                              v-show="errors.has('Department Name')"
                            >{{ errors.first('Department Name') }}</span>-->
                          </div>
                        </div>
                      </div>

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Field Type</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <div class>
                            <!-- <vs-select
                              v-validate="'required'"
                              v-model="Expenseform.field_type"
                              name="field type"
                              class="select-large w-full"
                            >
                              <vs-select-item key="index1" value="Number" text="Number" />

                              <vs-select-item key="index2" value="Text" text="Text" />

                              <vs-select-item key="index3" value="Values" text="Values" />
                            </vs-select>-->

                            <!-- <span
                              class="text-danger text-sm"
                              v-show="errors.has('Company Name')"
                            >{{ errors.first('Company Name') }}</span>-->
                            <multiselect
                              v-model="Evalue"
                              track-by="field_id"
                              label="values"
                              @input="({field_id})=> this.field_id = field_id"
                              :options="Efield_type"
                              name="Field Name"
                              :searchable="false"
                              :allow-empty="true"
                              open-direction="bottom"
                              deselect-label="remove this value"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >{{ option.values }}</template>
                            </multiselect>
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('Field Name')"
                            >{{ errors.first('Field Name') }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right">
                          <h5 class="mb-0">Status</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <vs-switch vs-icon-off="2" vs-icon-on="1" v-model="form.department_active" />
                          <b-form-checkbox value="1" unchecked-value="2" name="check-button" switch></b-form-checkbox>
                        </div>
                      </div>-->

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Default Value</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <div class>
                            <vs-input
                              v-validate="'required'"
                              v-model="Expenseform.field_value"
                              name="default value"
                              class="w-full"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Is it mandatory</h5>
                        </div>

                        <div class="vx-col sm:w-2/3 w-full">
                          <div>
                            <vs-radio
                              v-model="Expenseform.field_is_mandatory"
                              vs-name="radios1"
                              vs-value="1"
                              class="mr-3"
                            >Yes</vs-radio>
                            <vs-radio
                              v-model="Expenseform.field_is_mandatory"
                              vs-name="radios1"
                              vs-value="2"
                            >No</vs-radio>
                          </div>
                        </div>
                      </div>

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Is it unique</h5>
                        </div>

                        <div class="vx-col sm:w-2/3 w-full">
                          <div>
                            <vs-radio
                              v-model="Expenseform.field_is_unique"
                              vs-name="radios2"
                              vs-value="3"
                              class="mr-3"
                            >Yes</vs-radio>
                            <vs-radio
                              v-model="Expenseform.field_is_unique"
                              vs-name="radios2"
                              vs-value="4"
                            >No</vs-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- SUBMIT BUTTON -->
                  <div class="vx-row">
                    <div class="vx-col mx-auto">
                      <vs-button
                        type="filled"
                        @click.prevent="submitExpenseForm"
                        class="mr-3"
                      >Submit</vs-button>

                      <vs-button
                        color="warning"
                        type="border"
                        class
                        @click.prevent="clearExpenseForm"
                      >Reset</vs-button>
                    </div>
                  </div>
                </form>
              </vx-card>
            </div>
          </div>
          <!-- COMPANY LIST -->
        </div>
      </vs-tab>
      <vs-tab label="Challan" icon-pack="feather" icon="icon-box">
        <div class="tab-text">
          <!-- ADD DEPARTMENT FORM  -->
          <div class="vx-row">
            <!-- HORIZONTAL LAYOUT -->
            <div class="vx-col w-full">
              <vx-card class="form-title" title="Challan">
                <form>
                  <div class="vx-row">
                    <!-- LEFT SIDE -->
                    <div class="vx-col md:w-3/4 mx-auto">
                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Name</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <div class>
                            <vs-input
                              v-validate="'required'"
                              v-model="Challanform.field_name"
                              name="name"
                              class="w-full"
                            />
                            <!-- <span
                              class="text-danger text-sm"
                              v-show="errors.has('Department Name')"
                            >{{ errors.first('Department Name') }}</span>-->
                          </div>
                        </div>
                      </div>

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Field Type</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <div class>
                            <!-- <vs-select
                              v-validate="'required'"
                              v-model="Challanform.field_type"
                              name="field type"
                              class="select-large w-full"
                            >
                              <vs-select-item key="index4" value="Number" text="Number" />

                              <vs-select-item key="index5" value="Text" text="Text" />

                              <vs-select-item key="index6" value="Values" text="Values" />
                            </vs-select>

                            -->
                            <!-- <span
                              class="text-danger text-sm"
                              v-show="errors.has('Company Name')"
                            >{{ errors.first('Company Name') }}</span>-->
                            <multiselect
                              v-model="Cvalue"
                              track-by="field_id"
                              label="values"
                              @input="({field_id})=> this.field_id = field_id"
                              :options="Cfield_type"
                              name="Field Name"
                              :searchable="false"
                              :allow-empty="false"
                              open-direction="bottom"
                              deselect-label="Can't remove this value"
                            >
                              <template
                                slot="singleLabel"
                                slot-scope="{ option }"
                              >{{ option.values }}</template>
                            </multiselect>
                            <span
                              class="text-danger text-sm"
                              v-show="errors.has('Field Name')"
                            >{{ errors.first('Field Name') }}</span>
                          </div>
                        </div>
                      </div>

                      <!-- <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right">
                          <h5 class="mb-0">Status</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <vs-switch vs-icon-off="2" vs-icon-on="1" v-model="form.department_active" />
                          <b-form-checkbox value="1" unchecked-value="2" name="check-button" switch></b-form-checkbox>
                        </div>
                      </div>-->

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Default Value</h5>
                        </div>
                        <div class="vx-col sm:w-2/3 w-full">
                          <div class>
                            <vs-input
                              v-validate="'required'"
                              v-model="Challanform.field_value"
                              name="default value"
                              class="w-full"
                            />
                          </div>
                        </div>
                      </div>

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Is it mandatory</h5>
                        </div>
                        <!-- 
                        <vs-radio v-model="radios1" vs-name="radios1" vs-value="luis">Luis</vs-radio>
                        <vs-radio v-model="radios1" vs-name="radios1" vs-value="carols">Carols</vs-radio>-->
                        <div class="vx-col sm:w-2/3 w-full">
                          <div>
                            <vs-radio
                              v-model="Challanform.field_is_mandatory"
                              vs-name="radios1"
                              class="mr-3"
                              vs-value="1"
                            >Yes</vs-radio>
                            <vs-radio
                              v-model="Challanform.field_is_mandatory"
                              vs-name="radios1"
                              vs-value="2"
                            >No</vs-radio>
                          </div>
                        </div>
                      </div>

                      <div class="vx-row mb-6">
                        <div class="vx-col sm:w-1/5 w-full text-right align-self-center">
                          <h5 class="mb-0">Is it unique</h5>
                        </div>

                        <div class="vx-col sm:w-2/3 w-full">
                          <div>
                            <vs-radio
                              v-model="Challanform.field_is_unique"
                              class="mr-3"
                              vs-name="radios2"
                              vs-value="3"
                            >Yes</vs-radio>
                            <vs-radio
                              v-model="Challanform.field_is_unique"
                              vs-name="radios2"
                              vs-value="4"
                            >No</vs-radio>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- SUBMIT BUTTON -->
                  <div class="vx-row">
                    <div class="vx-col mx-auto">
                      <vs-button
                        type="filled"
                        @click.prevent="submitChallanForm"
                        class="mr-3"
                      >Submit</vs-button>

                      <vs-button
                        color="warning"
                        type="border"
                        class
                        @click.prevent="clearChallanForm"
                      >Reset</vs-button>
                    </div>
                  </div>
                </form>
              </vx-card>
            </div>
          </div>
          <!-- COMPANY LIST -->
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
import customizeFieldService from "@/services/customizeFieldService.js";

export default {
  data() {
    return {
      Evalue: [],
      Cvalue: [],
      Efield_type: [
        { values: "Approve", field_id: 1 },
        { values: "Rejected", field_id: 2 },
        { values: "Pending", field_id: 0 },
      ],
      Expenseform: {
        field_name: "",
        field_value: "",
        field_is_mandatory: false,
        field_is_unique: false,
      },
      Cfield_type: [
        { values: "Approve", field_id: 1 },
        { values: "Rejected", field_id: 2 },
        { values: "Pending", field_id: 0 },
      ],
      Challanform: {
        field_name: "",
        field_value: "",
        field_is_mandatory: false,
        field_is_unique: false,
      },
      radios1: "po",
      radios2: "po2",
      radios3: "ch",
      radios4: "ch2",
    };
  },
  methods: {
    submitExpenseForm: function () {
      this.Expenseform.field_is_mandatory =
        this.Expenseform.field_is_mandatory == "1" ? true : false;
      this.Expenseform.field_is_unique =
        this.Expenseform.field_is_unique == "3" ? true : false;
      let payload = {
        ...this.Expenseform,
      };
      customizeFieldService
        .addCustomizeField(payload)
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode == "200") {
            this.$vs.notify({
              title: "Success!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Server Error",
            text: "Something went wrong!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearExpenseForm: function () {
      this.Expenseform = {
        field_name: "",
        field_type: "",
        field_value: "",
        field_is_mandatory: false,
        field_is_unique: false,
      };
    },
    submitChallanForm: function () {
      this.Challanform.field_is_mandatory =
        this.Challanform.field_is_mandatory == "1" ? true : false;
      this.Challanform.field_is_unique =
        this.Challanform.field_is_unique == "3" ? true : false;
      let payload = {
        ...this.Challanform,
      };
      console.log(this.Challanform);
      return false;
      customizeFieldService
        .addCustomizeField(payload)
        .then((response) => {
          const { data } = response;
          if (data && data.statusCode == "200") {
            this.$vs.notify({
              title: "Success!",
              text: data.data.message,
              iconPack: "feather",
              icon: "check_box",
              color: "success",
            });
          } else {
            this.$vs.notify({
              title: "Error!",
              text: data.error.message,
              iconPack: "feather",
              icon: "check_box",
              color: "warning",
            });
          }
        })
        .catch((err) => {
          this.$vs.notify({
            title: "Server Error",
            text: "Something went wrong!",
            iconPack: "feather",
            icon: "check_box",
            color: "warning",
          });
        });
    },
    clearChallanForm: function () {
      this.Challanform = {
        field_name: "",
        field_type: "",
        field_value: "",
        field_is_mandatory: false,
        field_is_unique: false,
      };
    },
  },
};
</script>

<style>
</style>