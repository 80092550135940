import Api from './Api.js';
const organization_id = () => {
  return window.localStorage.getItem("OrganizationID");
};

export default {
  addCustomizeField(payload) {
    return Api().post(`/v2/customizefield?organization_id=${organization_id()}`, payload);
  },
};
